// ConfirmationModal.js
import React from 'react';
import Modal from 'react-modal';

function ConfirmationModal({ isOpen, onRequestClose, onConfirm, message }) {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className="ModalContent"
            overlayClassName="ModalOverlay"
        >
            <h2>Are you sure?</h2>
            <p>{message}</p>
            <button onClick={onConfirm}>Yes, delete</button>
            <button onClick={onRequestClose}>Cancel</button>
        </Modal>
    );
}

export default ConfirmationModal;
