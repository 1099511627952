import axios from 'axios';

// Erstelle eine Axios-Instanz f.r die API
const api = axios.create({
    baseURL: 'https://start2.xanthos.at',
});

// Füge Interceptor hinzu, um das Access-Token bei jeder Anfrage hinzuzufügen
api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('accessToken');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

// Antwort-Interceptor zur Token-Erneuerung bei abgelaufenem Token
api.interceptors.response.use(
    (response) => response, // Erfolgreiche Antwort einfach zurückgeben
    async (error) => {
        const originalRequest = error.config;

        // Überprüfe, ob der Fehler auf ein abgelaufenes Token zurückzuführen ist
        if (error.response?.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true; // Verhindert endlose Wiederholungen

            const refreshToken = localStorage.getItem('refreshToken');
            if (refreshToken) {
                try {
                    const response = await api.post('/api/token/refresh/', {
                        refresh: refreshToken,
                    });

                    // Aktualisiere das Access-Token und speichere es
                    const newAccessToken = response.data.access;
                    localStorage.setItem('accessToken', newAccessToken);

                    // Füge das neue Access-Token zur ursprünglichen Anfrage hinzu und wiederhole diese
                    originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                    return api(originalRequest);
                } catch (refreshError) {
                    console.error("Token konnte nicht erneuert werden:", refreshError);
                    localStorage.removeItem('accessToken');
                    localStorage.removeItem('refreshToken');
                    window.location.href = '/'; // Umleitung zum Login bei Fehlschlagen
                }
            }
        }

        return Promise.reject(error);
    }
);
export default api;