import React, { useEffect, useState, useRef  } from 'react';
import axios from 'axios';
import ConfirmationModal from './ConfirmationModal';
import api from './api';
import Modal from 'react-modal';
// import jwtDecode from 'jwt-decode';
import { jwtDecode } from 'jwt-decode';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import Masonry from 'react-masonry-css';
import { debounce } from 'lodash';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPlus, faEdit } from '@fortawesome/free-solid-svg-icons';
import './App.css';

Modal.setAppElement('#root');

function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isDeleteNoteModalOpen, setIsDeleteNoteModalOpen] = useState(false);
    const [isDeleteLinkModalOpen, setIsDeleteLinkModalOpen] = useState(false);
    const [isDeleteCategoryModalOpen, setIsDeleteCategoryModalOpen] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null); // Speichert die zu löschende Note oder den Link
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isSuperuser, setIsSuperuser] = useState(false);
    const [isRegistering, setIsRegistering] = useState(false);
    const [isResettingPassword, setIsResettingPassword] = useState(false);
    const [error, setError] = useState('');
    const [categories, setCategories] = useState([]);
    const [tags, setTags] = useState([]); // Tags f.r Autocomplete
    const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
    const [isEditCategoryModalOpen, setIsEditCategoryModalOpen] = useState(false);
    const [isLinkModalOpen, setIsLinkModalOpen] = useState(false);
    const [editedCategory, setEditedCategory] = useState(null);
    const [newCategoryName, setNewCategoryName] = useState('');
    const [categoryError, setCategoryError] = useState('');
    const [isCreatingCategory, setIsCreatingCategory] = useState(false);
    const [linkError, setLinkError] = useState('');
    const [newLink, setNewLink] = useState({ title: '', url: '', description: '', categoryId: null, tags: [] });
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [selectedTag, setSelectedTag] = useState(null);
    const [isEditLinkModalOpen, setIsEditLinkModalOpen] = useState(false);
    const [editedLink, setEditedLink] = useState(null);
    const [showResendActivation, setShowResendActivation] = useState(false); // Zustandsvariable hinzufügen
    const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [apiKey, setApiKey] = useState('');
    const [isCustomizeModalOpen, setIsCustomizeModalOpen] = useState(false);
    const [backgroundColor, setBackgroundColor] = useState('#ffffff'); // Standard-Hintergrundfarbe


    const [notes, setNotes] = useState([]);
    const [newNoteContent, setNewNoteContent] = useState({});
    const [noteCategoryToAdd, setNoteCategoryToAdd] = useState(null);
    const adjustTextareaHeight = (textarea) => {
        if (textarea) {
            textarea.style.height = 'auto';
            textarea.style.height = `${Math.min(textarea.scrollHeight, 5000)}px`;
        }
    };
    // Definiere das Breakpoint-Objekt, um die Anzahl der Spalten anzupassen
    const breakpointColumnsObj = {
        default: 4,
        1100: 3,
        700: 2,
        500: 1
    };

    useEffect(() => {
        if (isAuthenticated && !isDataLoaded) {
            const loadCategories = async () => {
                try {
                    if (localStorage.getItem('accessToken')) {
                        const response = await api.get('/api/categories/');
                        setCategories(response.data);
                        setIsDataLoaded(true);
                    }
                } catch (error) {
                    console.error("Error on loading categories:", error);
                }
            };
            loadCategories();
            fetchTags();

            const loadNotes = async () => {
                try {
                    const response = await api.get('/api/notes/');
                    setNotes(response.data);
                } catch (error) {
                    console.error("Error loading notes:", error);
                }
            };
            loadNotes();

            const loadCustomization = async () => {
                try {
                    const response = await api.get('/api/users/customization/');
                    setBackgroundColor(response.data.background_color || '#ffffff');
                } catch (error) {
                    console.error("Error loading user customization:", error);
                }
            };
        
            loadCustomization();
        }
    }, [isAuthenticated, isDataLoaded]);

    // Anpassungen im Backend speichern
    const saveCustomization = async (newBackgroundColor) => {
        try {
            await api.put('/api/users/customization/', {
                background_color: newBackgroundColor,
            });
        } catch (error) {
            console.error("Error saving user customization:", error);
        }
    };

    const handleBackgroundColorChange = (e) => {
        const newColor = e.target.value;
        setBackgroundColor(newColor); // Farbe sofort anzeigen
    };

    const openDeleteNoteModal = (noteId) => {
        setItemToDelete({ id: noteId, type: 'note' });
        setIsDeleteNoteModalOpen(true);
    };
    const handleCloseCustomizeModal = () => {
        saveCustomization(backgroundColor);   // API-Aufruf zur Speicherung der finalen Farbe
        setIsCustomizeModalOpen(false);       // Modal schließen
    };
    
    const openDeleteLinkModal = (linkId, categoryId) => {
        setItemToDelete({ id: linkId, categoryId, type: 'link' });
        setIsDeleteLinkModalOpen(true);
    };
    
    const openDeleteCategoryModal = (categoryId) => {
        setItemToDelete({ id: categoryId, type: 'category' });
        setIsDeleteCategoryModalOpen(true);
    };

    const confirmDeleteCategory = async () => {
        try {
            await api.delete(`/api/categories/${itemToDelete.id}/`);
            setCategories(categories.filter(category => category.id !== itemToDelete.id));
            console.log("Category deleted successfully");
        } catch (error) {
            console.error("Error deleting category:", error);
        } finally {
            setIsDeleteCategoryModalOpen(false);
            setItemToDelete(null);
        }
    };
    
    const confirmDeleteNote = async () => {
        try {
            await api.delete(`/api/notes/${itemToDelete.id}/`);
            setNotes(prevNotes => prevNotes.filter(note => note.id !== itemToDelete.id));
            console.log("Note deleted successfully");
        } catch (error) {
            console.error("Error deleting note:", error);
        } finally {
            setIsDeleteNoteModalOpen(false);
            setItemToDelete(null);
        }
    };
    
    const confirmDeleteLink = async () => {
        try {
            await api.delete(`/api/links/${itemToDelete.id}/`);
            setCategories(categories.map(category => {
                if (category.id === itemToDelete.categoryId) {
                    return {
                        ...category,
                        links: category.links.filter(link => link.id !== itemToDelete.id)
                    };
                }
                return category;
            }));
            console.log("Link deleted successfully");
        } catch (error) {
            console.error("Error deleting link:", error);
        } finally {
            setIsDeleteLinkModalOpen(false);
            setItemToDelete(null);
        }
    };

    const handleCreateNote = async (categoryId) => {
        if (!newNoteContent[categoryId]?.trim()) return;  // Verhindern von leeren Notizen
    
        try {
            const response = await api.post('/api/notes/', {
                content: newNoteContent[categoryId],
                order: notes.filter(note => note.category === categoryId).length,
                category: categoryId
            });
            setNotes([...notes, response.data]);
            setNewNoteContent((prev) => ({ ...prev, [categoryId]: '' }));  // Textfeld leeren für die Kategorie
            // Eingabefeld ausblenden
            setNoteCategoryToAdd(null);
        } catch (error) {
            console.error("Error creating note:", error);
        }
    };

    const cancelNoteInput = () => {
        setNoteCategoryToAdd(null);
        setNewNoteContent((prev) => ({ ...prev, [noteCategoryToAdd]: '' }));
    };

    const debouncedSaveNoteContent = debounce((noteId, newContent) => {
        saveNoteContent(noteId, newContent);
    }, 5000);
    
    const handleNoteContentChange = (noteId, newContent) => {
        setNotes((prevNotes) =>
            prevNotes.map((note) =>
                note.id === noteId ? { ...note, content: newContent } : note
            )
        );
        // debouncedSaveNoteContent(noteId, newContent);
    };
    const saveNoteContent = async (noteId, newContent) => {
        try {
            const noteToUpdate = notes.find(note => note.id === noteId);
            if (!noteToUpdate) {
                console.error("Note not found");
                return;
            }
    
            await api.put(`/api/notes/${noteId}/`, {
                content: newContent,
                category: noteToUpdate.category,  // Kategorie wird hinzugefügt
                order: noteToUpdate.order         // Optional: Reihenfolge hinzufügen
            });
    
            console.log("Note updated successfully");
        } catch (error) {
            console.error("Error updating note:", error);
        }
    };

    const handleDeleteNote = async (noteId) => {
        const confirmed = window.confirm("Are you sure you want to delete this note?");
        if (!confirmed) return;
    
        try {
            await api.delete(`/api/notes/${noteId}/`);
            
            // Entferne die Notiz im lokalen Zustand
            setNotes((prevNotes) => prevNotes.filter(note => note.id !== noteId));
            console.log("Note deleted successfully");
        } catch (error) {
            console.error("Error deleting note:", error);
        }
    };

    const saveNoteOrder = async (updates) => {
        try {
            await api.post('/api/update_notes_order/', { updates });
            console.log("Note order updated successfully!");
        } catch (error) {
            console.error("Error updating note order:", error);
        }
    };

    const handleNoteContentBlur = async (noteId, newContent) => {
        try {
            const noteToUpdate = notes.find(note => note.id === noteId);
            if (!noteToUpdate) {
                console.error("Note not found");
                return;
            }
            await api.put(`/api/notes/${noteId}/`, { 
                content: newContent,
                category: noteToUpdate.category,  // Kategorie wird hinzugefügt
                order: noteToUpdate.order         // Optional: Reihenfolge hinzufügen
            });
            console.log("Note updated successfully on blur");
        } catch (error) {
            console.error("Error updating note:", error);
        }
    };
    // um die textbox dynamisch zu erweitern
    const handleNoteContentInput = (e, noteId) => {
        const textarea = e.target;
    
        // Automatische Höhe zurücksetzen und neu berechnen
        textarea.style.height = 'auto';
        textarea.style.height = `${Math.min(textarea.scrollHeight, 5000)}px`;
    
        handleNoteContentChange(noteId, textarea.value);
    };
    
    const handleKeyDown = (e, noteId, content) => {
        if (e.key === 'Enter') {
            // e.preventDefault();  // Verhindert das Hinzufügen einer neuen Zeile bei Enter
            handleNoteContentBlur(noteId, content);
        }
    };

    const deleteCategory = async (categoryId) => {
        const confirmed = window.confirm("Do you really want to delete this category? All links will be deleted!");
    if (!confirmed) {
        return; // Abbrechen, wenn der Benutzer nicht bestätigt
    }
        try {
          await api.delete(`/api/categories/${categoryId}/`);
          // Entferne die gelöschte Kategorie aus dem State
          setCategories(categories.filter((category) => category.id !== categoryId));
        } catch (error) {
          console.error('Error deleting category:', error);
        }
      };

      
      const onDragEnd = (result) => {
        const { source, destination, draggableId, type } = result;
    
        // Abbrechen, wenn keine Zielposition vorhanden ist
        if (!destination) {
            return;
        }
    
        // Abbrechen, wenn das Element an dieselbe Position zurückgelegt wird
        if (source.droppableId === destination.droppableId && source.index === destination.index) {
            return;
        }
    
        if (type === 'note') {
            handleNoteDrag(source, destination, draggableId);
        } else if (type === 'link') {
            handleLinkDrag(source, destination, draggableId);
        }
    };

    const handleNoteDrag = (source, destination, draggableId) => {
        const noteId = parseInt(draggableId.split('-')[1]);
        console.log("noteid: " + noteId)
        const sourceCategoryId = parseInt(source.droppableId.split('-')[1]);
        console.log("sourceCategoryId: " + sourceCategoryId)
        const destinationCategoryId = parseInt(destination.droppableId.split('-')[1]);
        console.log("destinationCategoryId: " + destinationCategoryId)
        // Finde die Notiz und aktualisiere die Kategorie
        const updatedNotes = [...notes];
        const noteIndex = updatedNotes.findIndex(note => note.id === noteId);
    
        if (noteIndex === -1) {
            console.error("Note not found for dragging");
            return;
        }
    
        const [movedNote] = updatedNotes.splice(noteIndex, 1);
        movedNote.category = destinationCategoryId; // Aktualisiere die Kategorie
    
        // Füge die Notiz an der neuen Position in der Zielkategorie ein
        const newNotes = [
            ...updatedNotes.filter(note => note.category !== destinationCategoryId),
            ...updatedNotes.filter(note => note.category === destinationCategoryId),
        ];
        newNotes.splice(destination.index, 0, movedNote);
    
        // Aktualisiere den Zustand lokal
        setNotes(newNotes);
    
        // Backend-Aufruf zum Speichern der neuen Kategorie und der neuen Reihenfolge
        saveNoteOrder(newNotes.map((note, index) => ({
            id: note.id,
            order: index,
            category: note.category,
        })));
    };
    const handleLinkDrag = (source, destination, draggableId) => {
        console.log("Starting handleLinkDrag...");
        console.log("Source:", source);
        console.log("Destination:", destination);
        console.log("Draggable ID:", draggableId);
    
        // Extrahiere die IDs aus den Strings wie "category-123" oder "notes-456"
        const sourceCategoryId = parseInt(source.droppableId.split('-')[1]);
        const destinationCategoryId = parseInt(destination.droppableId.split('-')[1]);

        console.log("Source Category ID:", sourceCategoryId);
        console.log("Destination Category ID:", destinationCategoryId);
    
        // Kopiere die Kategorien, um den Zustand zu aktualisieren
        const updatedCategories = [...categories];
        console.log("Updated Categories before changes:", updatedCategories);
    
        // Finde die Quell- und Zielkategorie
        const sourceCategoryIndex = updatedCategories.findIndex(cat => cat.id === sourceCategoryId);
        const destinationCategoryIndex = updatedCategories.findIndex(cat => cat.id === destinationCategoryId);
    
        if (sourceCategoryIndex === -1 || destinationCategoryIndex === -1) {
            console.error("Category not found for source or destination");
            return;
        }
    
        // Finde den Link, der verschoben werden soll
        const linkId = parseInt(draggableId.split('-')[1]);
        console.log("Parsed Link ID:", linkId);

        const linkIndex = updatedCategories[sourceCategoryIndex].links.findIndex(link => link.id === linkId);
        if (linkIndex === -1) {
            console.error("Link not found for dragging. Possible issues:");
            console.error("- Check if draggableId matches the link ID.");
            console.error("- Check if the source category contains the expected links.");
            console.error("- Draggable ID:", draggableId);
            console.error("- Source Category Links:", updatedCategories[sourceCategoryIndex].links);
            return;
        }
    
        console.log("Source Category Index:", sourceCategoryIndex);
        console.log("Destination Category Index:", destinationCategoryIndex);
        console.log("Link Index in Source Category:", linkIndex);
    
        // Entferne den Link aus der Quellkategorie und füge ihn zur Zielkategorie hinzu
        const [movedLink] = updatedCategories[sourceCategoryIndex].links.splice(linkIndex, 1);
        movedLink.category = destinationCategoryId; // Aktualisiere die Kategorie des Links
    
        console.log("Moved Link:", movedLink);
    
        updatedCategories[destinationCategoryIndex].links.splice(destination.index, 0, movedLink);
        console.log("Updated Categories after changes:", updatedCategories);
    
        // Aktualisiere den Zustand
        setCategories(updatedCategories);
        console.log("Categories state updated.");
    
        // Optional: Backend-Aufruf, um die neue Reihenfolge und Kategorie zu speichern
        const updates = [];
        updatedCategories.forEach(category => {
            category.links.forEach((link, index) => {
                updates.push({
                    link_id: link.id,
                    category_id: category.id,
                    order: index,
                });
            });
        });

        console.log("Prepared updates for backend:", updates);

        saveLinkOrder(updates);
    };
    
    const saveLinkOrder = async (updates) => {
        console.log("Preparing to save link order with the following updates:", updates);
        try {
            const response = await api.post('/api/update_link_order/', {
                updates: updates
            });
            console.log("Link order successfully saved:", response.data);
        } catch (error) {
            console.error("Fehler beim Speichern der Kategorie und Reihenfolge:", error);
            if (error.response) {
                console.error("API response:", error.response.data);
            }
        }
    };

    // API-Aufruf durchführen
    // Tags aus der Datenbank laden
    const fetchTags = async () => {
        try {
            if (localStorage.getItem('accessToken')) {
            const response = await api.get('/api/tags/'); // Tags-API hinzuf.gen
            setTags(response.data.map(tag => ({ label: tag.name, value: tag.id }))); // Autocomplete-Format
            }
        } catch (error) {
            console.error("Fehler beim Laden der Tags:", error);
        }
    };


    const handleTagsChange = (selectedTags) => {
        setNewLink((prevLink) => ({
            ...prevLink,
            tags: selectedTags || []
        }));
    };

    const handleTagClick = (tag) => {
        if (selectedTag && selectedTag.value === tag.value) {
            setSelectedTag(null); // Tag abwählen, wenn er erneut angeklickt wird
        } else {
            setSelectedTag(tag);
        }
    };

    const getUsedTags = () => {
        const usedTagIds = new Set();
        
        categories.forEach(category => {
            category.links.forEach(link => {
                link.tags.forEach(tagId => {
                    usedTagIds.add(tagId);
                });
            });
        });
    
        return tags.filter(tag => usedTagIds.has(tag.value));
    };

    // Handler für das Bearbeiten eines Links
const handleEditLink = (link) => {
    setEditedLink(link);
    setNewLink({ 
        title: link.title, 
        url: link.url, 
        description: link.description, 
        categoryId: link.category, 
        tags: link.tags.map(tagId => tags.find(tag => tag.value === tagId)) 
    });
    setIsEditLinkModalOpen(true);
};

// Handler für das updaten eines Links NACH der Bearbeitung
const handleUpdateLink = async () => {
    // Validierung der Pflichtfelder
    if (!newLink.title || !newLink.url) {
        setLinkError('Please fill out every field needed (title, URL).');
        return;
    }
    setLinkError(''); // Fehler zurücksetzen

    try {
        const response = await api.put(`/api/links/${editedLink.id}/`, {
            title: newLink.title,
            url: newLink.url,
            description: newLink.description,
            category: newLink.categoryId,
            tags: newLink.tags.length > 0 ? newLink.tags.map(tag => tag.value) : [], // Leeres Array, falls keine Tags
        });

        // Aktualisiere den Zustand, indem der bearbeitete Link ersetzt wird
        const updatedCategories = categories.map(cat => {
            if (cat.id === newLink.categoryId) {
                return {
                    ...cat,
                    links: cat.links.map(link =>
                        link.id === editedLink.id ? response.data : link
                    ),
                };
            }
            return cat;
        });

        setCategories(updatedCategories);
        setIsEditLinkModalOpen(false);
        setEditedLink(null);
        setNewLink({ title: '', url: '', description: '', categoryId: null, tags: [] });
    } catch (error) {
        console.error("Fehler beim Aktualisieren des Links:", error);
    }
};

const handleDeleteLink = async (linkId, categoryId) => {
    const confirmed = window.confirm("Do you really want to delete this link?");
    if (!confirmed) return;

    try {
        await api.delete(`/api/links/${linkId}/`);
        
        // Entferne den Link im lokalen Zustand
        const updatedCategories = categories.map(category => {
            if (category.id === categoryId) {
                return {
                    ...category,
                    links: category.links.filter(link => link.id !== linkId)
                };
            }
            return category;
        });
        setCategories(updatedCategories);
    } catch (error) {
        console.error("error on deleting links:", error);
    }
};



const handleCreateNewTag = async (inputValue) => {
   console.log("Neuer Tag erstellt:", inputValue); // Debugging: Eingabe .berpr.fen

    try {
        // Sende den neuen Tag an die API
        const response = await api.post('/api/tags/', { name: inputValue });
        const newTag = { label: response.data.name, value: response.data.id };

        // F.ge den neuen Tag zur Liste der verf.gbaren Tags hinzu
        setTags((prevTags) => [...prevTags, newTag]);

        // F.ge den neuen Tag direkt zum Link hinzu
        setNewLink((prevLink) => ({ ...prevLink, tags: [...prevLink.tags, newTag] }));
    } catch (error) {
        console.error("Fehler beim Erstellen eines neuen Tags:", error);
    }
};

const handleRemoveTag = (tagToRemove) => {
    setNewLink((prevLink) => ({
        ...prevLink,
        tags: prevLink.tags.filter(tag => tag.value !== tagToRemove.value)
    }));
};


    // Neue Kategorie erstellen
    const handleCreateCategory = async () => {
    if (!newCategoryName) {
        setCategoryError('Please input a category name.');
        return;
    }
    setCategoryError(''); // Fehler zur.cksetzen, falls keine Validierungsprobleme auftreten
    setIsCreatingCategory(true);

        try {
            const response = await api.post('/api/categories/', { name: newCategoryName });
            setCategories([...categories, response.data]);
            setNewCategoryName('');
            closeCategoryModal();
        } catch (error) {
            console.error("Error creating category:", error);
        } finally {
            setIsCreatingCategory(false);
        }
    };

    // Kategorie bearbeiten
    const handleEditCategory = (category) => {
        setEditedCategory(category);
        setNewCategoryName(category.name);
        setIsEditCategoryModalOpen(true);
    };

    // Kategorie updaten
    const handleUpdateCategory = async () => {
        if (!newCategoryName) {
            setCategoryError('Please input a category name.');
            return;
        }
        setCategoryError('');
    
        try {
            const response = await api.put(`/api/categories/${editedCategory.id}/`, { name: newCategoryName });
            const updatedCategories = categories.map((category) =>
                category.id === editedCategory.id ? response.data : category
            );
            setCategories(updatedCategories);
            setNewCategoryName('');
            setEditedCategory(null);
            setIsEditCategoryModalOpen(false);
        } catch (error) {
            console.error("Fehler beim Aktualisieren der Kategorie:", error);
        }
    };

    // Neuen Link erstellen
    const handleCreateLink = async () => {
    // Validierung der Pflichtfelder
    if (!newLink.title || !newLink.url ) {
        setLinkError('Please fill out every field needed (title, URL).');
        return;
    }
    setLinkError(''); // Fehler zurücksetzen, falls keine Validierungsprobleme auftreten


        try {
            const response = await api.post('/api/links/', {
                title: newLink.title,
                url: newLink.url,
                description: newLink.description,
                category: newLink.categoryId,
                tags: newLink.tags.length > 0 ? newLink.tags.map(tag => tag.value) : [], // Leeres Array, falls keine Tags

            });
            const updatedCategories = categories.map(cat =>
                cat.id === newLink.categoryId ? { ...cat, links: [...cat.links, response.data] } : cat
            );
            setCategories(updatedCategories);
            setNewLink({ title: '', url: '', description: '', categoryId: null, tags: [] });
            closeLinkModal();
        } catch (error) {
            console.error("Fehler beim Erstellen des Links:", error);
        }
    };

    // Modal- und Button-Handling
    const openCategoryModal = () => setIsCategoryModalOpen(true);
    const closeCategoryModal = () => setIsCategoryModalOpen(false);
    const openLinkModal = (categoryId) => {
        setNewLink({ ...newLink, categoryId });
        setIsLinkModalOpen(true);
    };
    const closeLinkModal = () => setIsLinkModalOpen(false);

///////////////////////////////////////////////////////////////
///////////////////////////////////
// AUTHENTIFICATION
const renewAccessToken = async (refreshToken) => {
    try {
        const response = await api.post('/api/token/refresh/', {
            refresh: refreshToken,
        });
        const newAccessToken = response.data.access;
        localStorage.setItem('accessToken', newAccessToken);
        setIsAuthenticated(true);
    } catch (error) {
        console.error("Token konnte nicht erneuert werden:", error);
        setIsAuthenticated(false);
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
    }
};
const checkAndRenewToken = async () => {
    const refreshToken = localStorage.getItem('refreshToken');
    const accessToken = localStorage.getItem('accessToken');

    if (accessToken) {
        const decodedToken = jwtDecode(accessToken);
        const currentTime = Date.now() / 1000;

        // Wenn das Token in weniger als 5 Minuten abläuft, erneuere es
        if (decodedToken.exp - currentTime < 300 && refreshToken) {
            try {
                await renewAccessToken(refreshToken);
            } catch (error) {
                console.error("Token konnte nicht erneuert werden:", error);
                localStorage.removeItem('accessToken');
                localStorage.removeItem('refreshToken');
                setIsAuthenticated(false);
            }
        }
    }
};
    // Token Renewal Interval
    useEffect(() => {
        const interval = setInterval(() => {
            const refreshToken = localStorage.getItem('refreshToken');
            if (refreshToken) {
                checkAndRenewToken();
            }
        }, 15 * 60 * 1000); // Alle 60 Minuten versuchen, den Token zu erneuern

        return () => clearInterval(interval); // Intervalle bereinigen, wenn der Effekt entfernt wird
    }, [isAuthenticated]);

    // Prüfe, ob ein Token bereits vorhanden ist (z.B. nach Seitenneuladen)
    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken');
    
        if (!token && !refreshToken) {
            setIsAuthenticated(false);
            return;
        }
    
        try {
            if (token) {
                const decodedToken = jwtDecode(token);
                const currentTime = Date.now() / 1000; // aktuelle Zeit in Sekunden
    
                if (decodedToken.exp > currentTime) {
                    setIsAuthenticated(true);
                    checkIfSuperuser(); // Check if the logged-in user is a superuser
                    fetchUserInfo(); // Benutzerinformationen, wie die E-Mail, abrufen


                } else if (refreshToken) {
                    // Token ist abgelaufen, versuche zu erneuern
                    renewAccessToken(refreshToken);
                } else {
                    setIsAuthenticated(false);
                    localStorage.removeItem('accessToken');
                    localStorage.removeItem('refreshToken');
                }
            } else if (refreshToken) {
                renewAccessToken(refreshToken);
            }
        } catch (error) {
            console.error("Fehler beim Decodieren des Tokens:", error);
            setIsAuthenticated(false);
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
        }
    }, []);
    const fetchUserInfo = async () => {
        try {
            const response = await api.get('/api/users/info/');
            setEmail(response.data.email);
        } catch (error) {
            console.error("Fehler beim Abrufen der Benutzerinformationen:", error);
        }
    };

    const checkIfSuperuser = async () => {
        try {
            const response = await api.get('/api/users/is_superuser/');
            setIsSuperuser(response.data.is_superuser);
        } catch (error) {
            console.error("Fehler beim Überprüfen des Superuser-Status:", error);
            setIsSuperuser(false);
        }
    };
    // Login-Handler
    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            console.log("Attempting to login at: /api/users/login/");
            const response = await api.post('/api/users/login/', {
                username,
                password
            });
            const { access, refresh } = response.data;
            localStorage.setItem('accessToken', access);
            localStorage.setItem('refreshToken', refresh);
            setIsAuthenticated(true);
            setError('');
            checkIfSuperuser();
            fetchUserInfo();

        } catch (error) {
            if (error.response?.status === 401) {
                const errorMessage = error.response?.data?.detail;
                if (errorMessage === 'Your account is not activated. Please activate your account before logging in.') {
                    setError('Your account is not activated. Please check your email to activate it or request another activation email.');
                    setShowResendActivation(true);  // Setze eine neue Zustandsvariable
                } else {
                    setError('Wrong login credentials. Please check user and password.');
                }
            } else {
                setError('An error occurred. Please try again.');
            }
        }
    };

    const handleResendActivationEmail = async () => {
        try {
            await api.post('/api/users/resend-activation/', { username });
            alert('Activation email has been resent. Please check your inbox.');
        } catch (error) {
            console.error('Error resending activation email:', error);
        }
    };

    // Logout-Handler
    const handleLogout = () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        setIsAuthenticated(false);
        setCategories([]);
        setTags([]);
        setIsDataLoaded(false);
        setIsSuperuser(false);
    };

    const openSettingsModal = () => {
        setIsSettingsModalOpen(true);
        fetchUserInfo(); // Benutzerinformationen abrufen, wenn das Modal geöffnet wird
    };

    const closeSettingsModal = () => {
        setIsSettingsModalOpen(false);
    };

    const handleChangePassword = async () => {
        try {
            const response = await api.post('/api/users/change_password/', {
                old_password: oldPassword,
                new_password: newPassword,
            });
            alert(response.data.message);
            setOldPassword('');
            setNewPassword('');
        } catch (error) {
            alert('Error changing password: ' + error.response.data.error);
        }
    };

    const handleChangeEmail = async () => {
        try {
            const response = await api.post('/api/users/change_email/', {
                email: email,
            });
            alert(response.data.message);
            fetchUserInfo(); 
        } catch (error) {
            alert('Error updating email: ' + error.response.data.error);
        }
    };

    const fetchApiKey = async () => {
        try {
            const response = await api.get('/api/users/api_key/');
            setApiKey(response.data.api_key);
        } catch (error) {
            console.error("Fehler beim Abrufen des API-Schlüssels:", error);
        }
    };

    useEffect(() => {
        if (isSettingsModalOpen) {
            fetchApiKey();
        }
    }, [isSettingsModalOpen]);

    useEffect(() => {
        // Führe die Anpassung der Textareas verzögert aus, damit das DOM vollständig gerendert ist
        const timeoutId = setTimeout(() => {
            const textareas = document.querySelectorAll('.note-content');
            textareas.forEach((textarea) => {
                adjustTextareaHeight(textarea);
            });
        }, 100); // 100 Millisekunden Verzögerung
    
        return () => clearTimeout(timeoutId); // Timeout aufräumen, wenn der Effekt nicht mehr benötigt wird
    }, [notes]);

    const handleRegister = async (e) => {
        e.preventDefault();
        try {
            const response = await api.post('/api/users/register/', {
                username,
                email,
                password,
            });
            alert(response.data.message);
            setIsRegistering(false);  // Zurück zum Login-Formular
        } catch (error) {
            console.error("Registration failed:", error);
        }
    };
    const handlePasswordReset = async (e) => {
        e.preventDefault();
        try {
            const response = await api.post('/api/users/password-reset/', { email });
            alert(response.data.message);
        } catch (error) {
            console.error("Error on trying to reset password:", error);
        }
    };
    const handleShowRegister = () => {
        setIsRegistering(true);
        setIsResettingPassword(false);
    };
    
    const handleShowResetPassword = () => {
        setIsResettingPassword(true);
        setIsRegistering(false);
    };

    
    // Registrierungs Form
    if (isRegistering) {
        return (
            <div>
                <h2>Register</h2>
                <form onSubmit={handleRegister}>
                    <div>
                        <label>Username:</label>
                        <input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label>Email:</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div>
                        <label>Password:</label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                    <button type="submit">Register</button>
                    <button onClick={() => setIsRegistering(false)}>Cancel</button>
                </form>
            </div>
        );
    }

    // Passwort Reset Form

    if (isResettingPassword) {
        return (
            <div>
                <h2>Reset Password</h2>
                <form onSubmit={handlePasswordReset}>
                    <div>
                        <label>Email:</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                    <button type="submit">Send Reset Link</button>
                    <button onClick={() => setIsResettingPassword(false)}>Cancel</button>
                </form>
            </div>
        );
    }

    // Login-Formular anzeigen, wenn der Benutzer nicht authentifiziert ist
    if (!isAuthenticated) {

        return (
            <div className="login-container">
                <div className="login-box">
                    <h2>Login</h2>
                    <form onSubmit={handleLogin}>
                        <div className="form-group">
                            <label>Username:</label>
                            <input
                                type="text"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Password:</label>
                            <input
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </div>
                        {error && <p className="error-message">{error}</p>}
                        {showResendActivation && (
                            <button type="button" onClick={handleResendActivationEmail}>
                                Resend Activation Email
                            </button>
                        )}
                        <button type="submit" className="login-button">Login</button>
        </form>
                    <div className="auth-links">
                <p>
                    <a href="#" onClick={handleShowRegister}>Register here</a>
                    <br />
                    <a href="#" onClick={handleShowResetPassword}>Forgot Password?</a>
                </p>
            </div>
                </div>
            </div>
        );
    }

    
    

/// AUTH END





return (
    
    <DragDropContext onDragEnd={onDragEnd}>
         <div className="app-container" style={{ backgroundColor }}>
        <header className="app-header">
            <div className="header-logo">
                <h1>Starty</h1>
            </div>
            <div className="header-actions">
                <button onClick={handleLogout}>Logout</button>
                {isSuperuser && (
                <button onClick={() => window.location.href = '/admin'} className="admin-button" title="Administration">Administration</button>
                )}
                <button onClick={openCategoryModal} title="Create a new category">New category</button>
                <button onClick={openSettingsModal}>Settings</button>
                <button onClick={() => setIsCustomizeModalOpen(true)} title="Customize" className="customize-button">
                    <FontAwesomeIcon icon={faEdit} />
                </button>
            </div>
        </header>
        
        <Modal isOpen={isSettingsModalOpen} onRequestClose={closeSettingsModal} contentLabel="Settings"
                className="ModalContent" overlayClassName="ModalOverlay">
                <h2>Settings</h2>
                <div className="settings-section">
                    <h3>Current Email</h3>
                    <input
                        type="email"
                        value={email}
                        readOnly
                        className="settings-readonly"
                    />
                </div>
                <div className="settings-section">
                    <h3>Change Email</h3>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="New Email"
                        className="settings-input"
                    />
                    <button onClick={handleChangeEmail}>Update Email</button>
                </div>
                <div className="settings-section">
                    <h3>Change Password</h3>
                    <input
                        type="password"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                        placeholder="Old Password" 
                        className="settings-input"
                    />
                    <input
                        type="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        placeholder="New Password"
                        className="settings-input"
                    />
                    <button onClick={handleChangePassword}>Change Password</button>
                </div>

                <div className="settings-section">
                    <h3>API Key</h3>
                    <p>Your API Key:</p>
                    <input type="text" value={apiKey} readOnly
                        className="settings-input"
                     />
                </div>
                <button onClick={closeSettingsModal}>Close</button>
            </Modal>

            <Modal
                isOpen={isCustomizeModalOpen}
                onRequestClose={handleCloseCustomizeModal}
                contentLabel="Customize Options"
                className="ModalContent"
                overlayClassName="ModalOverlay"
                style={{ top: '10%', right: '5%', width: '200px', position: 'fixed' }} // Position oben rechts
            >
                <h2>Customize</h2>
                <div className="customize-option">
                    <label htmlFor="backgroundColor">Background Color:</label>
                    <input
                        type="color"
                        id="backgroundColor"
                        value={backgroundColor}
                        onChange={handleBackgroundColorChange}
                    />
                </div>
                <button onClick={handleCloseCustomizeModal}>Close</button>
            </Modal>






            <div className="tags-filter">
                <span className="tags-label">Filter tags:</span>
                {getUsedTags().map((tag) => (
                    <button
                        key={tag.value}
                        onClick={() => handleTagClick(tag)}
                        className={`tag-filter-button ${selectedTag && selectedTag.value === tag.value ? 'selected' : ''}`}
                    >
                        {tag.label}
                    </button>
                ))}
            </div>
      
            <div className="category-container">
    <Masonry
        breakpointCols={breakpointColumnsObj}
        className="category-masonry-grid"
        columnClassName="category-masonry-column"
    >
        {categories.map((category) => (
            <Droppable key={`category-${category.id.toString()}`} droppableId={`category-${category.id}`} type="link">
                {(provided) => (
                    <div
                        className="category"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                        <div className="category-header">
                            <h2 className="category-title">{category.name}</h2>
                            <div className="category-buttons">
                                <button onClick={() => handleEditCategory(category)} title="Edit Category">
                                    <FontAwesomeIcon icon={faEdit} />
                                </button>
                                <button onClick={() => openLinkModal(category.id)} title="Add Link">
                                    <FontAwesomeIcon icon={faPlus} />
                                </button>
                                <button onClick={() => setNoteCategoryToAdd(noteCategoryToAdd === category.id ? null : category.id)} title="Add Note">
                                    <FontAwesomeIcon icon={faPlus} /> Note
                                </button>
                                <button className="delete-button" title="Delete Category" onClick={() => openDeleteCategoryModal(category.id)}>
                                    <FontAwesomeIcon icon={faTimes} />
                                </button>
                            </div>
                        </div>
                            {/* Links innerhalb der Kategorie anzeigen */}
                        {category.links && category.links.length > 0 ? (
                            <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
                                {category.links
                                    .filter(link => !selectedTag || link.tags.includes(selectedTag.value))
                                    .map((link, index) => (
                                        <Draggable key={`link-${link.id}`} draggableId={`link-${link.id}`} index={index}>
                                            {(provided) => (
                                                <li
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className="link-card"
                                                >
                                                    <div className="link-card-header">
                                                        <a href={link.url} target="_blank" rel="noopener noreferrer" className="link-title">
                                                            {link.title}
                                                        </a>
                                                        <button onClick={() => handleEditLink(link)} className="edit-link-button" title="Edit Link">
                                                            <FontAwesomeIcon icon={faEdit} />
                                                        </button>
                                                        <button onClick={() => openDeleteLinkModal(link.id, category.id)} className="remove-link-button">
                                                            <FontAwesomeIcon icon={faTimes} />
                                                        </button>
                                                    </div>
                                                    <p className="link-description">{link.description}</p>
                                                    {/* Tags anzeigen */}
                                                    <div className="link-tags">
                                                        {link.tags && link.tags.length > 0 ? (
                                                            link.tags.map((tagId) => {
                                                                const tag = tags.find(t => t.value === tagId);
                                                                return tag ? (
                                                                    <span key={tag.value} className="tag-item">
                                                                        {tag.label}
                                                                    </span>
                                                                ) : null;
                                                            })
                                                        ) : (
                                                            <span className="no-tags"></span>
                                                        )}
                                                    </div>
                                                </li>
                                            )}
                                        </Draggable>
                                    ))}
                            </ul>
                        ) : (
                            <p>No links within this category.</p>
                        )}

                        {/* Notizen innerhalb der Kategorie anzeigen */}
                        <Droppable droppableId={`notes-${category.id}`} type="note">
                            {(provided) => (
                                <div
                                    className="notes-container"
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {notes
                                        .filter(note => note.category === category.id)
                                        .map((note, index) => (
                                            
                                            <Draggable key={`note-${note.id}`} draggableId={`note-${note.id}`} index={index}>
                                                {(provided) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className="note-item"
                                                    >
                                                        <textarea
                                                            value={note.content}
                                                            // onChange={(e) => handleNoteContentChange(note.id, e.target.value)}
                                                            onChange={(e) => handleNoteContentInput(e, note.id)}
                                                            onBlur={() => handleNoteContentBlur(note.id, note.content)}
                                                            onKeyDown={(e) => handleKeyDown(e, note.id, note.content)}
                                                            className="note-content"
                                                            rows="1"
                                                            style={{ overflow: 'auto' }} // Scrollbalken erscheint, wenn der Inhalt größer ist
                                                        />
                                                        <button onClick={() => openDeleteNoteModal(note.id)} className="delete-note-button">
                                                            <FontAwesomeIcon icon={faTimes} />
                                                        </button>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>

                        {/* Eingabefeld für neue Notiz nur anzeigen, wenn vom Benutzer angefordert */}
                        {noteCategoryToAdd === category.id && (
                            <div className="new-note-container">
                                <textarea
                                    value={newNoteContent[category.id] || ''}
                                    onChange={(e) => setNewNoteContent({ ...newNoteContent, [category.id]: e.target.value })}
                                    placeholder="Write your note here..."
                                    className="new-note-input"
                                />
                                <button onClick={() => handleCreateNote(category.id)} className="add-note-button">
                                    <FontAwesomeIcon icon={faPlus} /> Add Note
                                </button>
                                <button onClick={cancelNoteInput} className="cancel-button">
                                    Cancel
                                </button>
                            </div>
                        )}

                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        ))}
    </Masonry>
</div>

{/* Modal f.r neue Kategorie */}
<Modal isOpen={isCategoryModalOpen} onRequestClose={closeCategoryModal} className="ModalContent" overlayClassName="ModalOverlay" contentLabel="Neue Kategorie">

    <h2>Create new category</h2>
    <form onSubmit={(e) => { e.preventDefault(); handleCreateCategory(); }}>
    <input
        type="text"
        value={newCategoryName}
        onChange={(e) => setNewCategoryName(e.target.value)}
        placeholder="Category Name"
        className="settings-input"
    />
    {categoryError && <p style={{ color: 'red' }}>{categoryError}</p>}
    <button type="submit" onClick={handleCreateCategory} disabled={isCreatingCategory}>Create</button>
    <button type="button" onClick={closeCategoryModal}>Cancel</button>
    </form>

</Modal>

{/* Modal für Kategorie bearbeiten */}
<Modal
    isOpen={isEditCategoryModalOpen}
    onRequestClose={() => setIsEditCategoryModalOpen(false)}  className="ModalContent" overlayClassName="ModalOverlay"
    contentLabel="Edit category"
>
    <h2>Edit category</h2>
    <input
        type="text"
        value={newCategoryName}
        onChange={(e) => setNewCategoryName(e.target.value)}  className="ModalContent" overlayClassName="ModalOverlay"
        placeholder="New category name"
    />
    {categoryError && <p style={{ color: 'red' }}>{categoryError}</p>}
    <button onClick={handleUpdateCategory}>Update</button>
    <button onClick={() => setIsEditCategoryModalOpen(false)}>Cancel</button>
</Modal>

{/* Modal f.r neuen Link */}
<Modal isOpen={isLinkModalOpen} onRequestClose={closeLinkModal} contentLabel="New link"
className="ModalContent" // Modal-Styling
    overlayClassName="ModalOverlay" // Overlay-Styling
>
    <h2>Add a new link</h2>
    <input
        type="text"
        value={newLink.title}
        onChange={(e) => setNewLink({ ...newLink, title: e.target.value })}
        placeholder="Title"
    />
    <input
        type="text"
        value={newLink.url}
        onChange={(e) => setNewLink({ ...newLink, url: e.target.value })}
        placeholder="URL"
    />
    <textarea
        value={newLink.description}
        onChange={(e) => setNewLink({ ...newLink, description: e.target.value })}
        placeholder="Description (optional)"
        rows="4"
    ></textarea>
<div className="SelectContainer">
    <CreatableSelect
        isMulti
        options={tags}
        value={newLink.tags}
        onChange={handleTagsChange}
        onCreateOption={handleCreateNewTag}
        placeholder="Choose tags or add new ones (optional)"
    />
</div>
    {linkError && <p style={{ color: 'red' }}>{linkError}</p>}
    <button onClick={handleCreateLink}>Add</button>
    <button onClick={closeLinkModal}>Cancel</button>
</Modal>

{/* Modal für Link editieren */}
<Modal
    isOpen={isEditLinkModalOpen}
    onRequestClose={() => setIsEditLinkModalOpen(false)}
    contentLabel="Edit link"
    className="ModalContent"
    overlayClassName="ModalOverlay"
>
    <h2>Edit link</h2>
    <input
        type="text"
        value={newLink.title}
        onChange={(e) => setNewLink({ ...newLink, title: e.target.value })}
        placeholder="Title"
    />
    <input
        type="text"
        value={newLink.url}
        onChange={(e) => setNewLink({ ...newLink, url: e.target.value })}
        placeholder="URL"
    />
    <textarea
        value={newLink.description}
        onChange={(e) => setNewLink({ ...newLink, description: e.target.value })}
        placeholder="Description (optional)"
        rows="4"
    ></textarea>
    <div className="SelectContainer">
        <CreatableSelect
            isMulti
            options={tags}
            value={newLink.tags}
            onChange={handleTagsChange}
            onCreateOption={handleCreateNewTag}
            placeholder="Choose tags or add new ones (optional)"
        />
    </div>
    {linkError && <p style={{ color: 'red' }}>{linkError}</p>}
    <button onClick={handleUpdateLink}>Aktualisieren</button>
    <button onClick={() => setIsEditLinkModalOpen(false)}>Abbrechen</button>
</Modal>

<ConfirmationModal
    isOpen={isDeleteNoteModalOpen}
    onRequestClose={() => setIsDeleteNoteModalOpen(false)}
    onConfirm={confirmDeleteNote}
    message="Do you really want to delete this note?"
/>

<ConfirmationModal
    isOpen={isDeleteLinkModalOpen}
    onRequestClose={() => setIsDeleteLinkModalOpen(false)}
    onConfirm={confirmDeleteLink}
    message="Do you really want to delete this link?"
/>

<ConfirmationModal
    isOpen={isDeleteCategoryModalOpen}
    onRequestClose={() => setIsDeleteCategoryModalOpen(false)}
    onConfirm={confirmDeleteCategory}
    message="Do you really want to delete this category?"
/>
</div>
    </DragDropContext>


    
);

}

export default App;
